import * as React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PostItemList from "../components/post-item-list"

import * as styles from "../styles/index.module.css"

const MathPage = () => {
  const data = useStaticQuery(graphql`
    query {
      math: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { 
          frontmatter: { 
            category: { eq: "math" }
            publish: { eq: true }
            hide: { eq: null }
          } 
        }
      ) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              date(formatString: "YYYY MMMM DD")
              category
              summary
              hideDate
              hideSummary
              sublinks {
                path
                text
                nonStandard
              }
            }
            excerpt
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <div>
        <h1 className={styles.title}>math</h1>
        <PostItemList className="Section" posts={data.math.edges.map(edge => edge.node)} />
      </div>
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="math" description="A collection of math-related articles."/>

export default MathPage
